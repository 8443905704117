import React, { useState } from 'react'
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import PageFlip from '../../components/PageFlip';
import components from '../../assets/images/stegvision/components.png';
import dashboard from '../../assets/images/stegvision/dashboard.png';
import Warning from '../../components/Warning';
import WorkHeader from '../../components/WorkHeader';
import Paragraph from '../../components/Paragraph';
import ProjectImage from '../../components/ProjectImage';


function Freelance() {

    useEffect(() => {
        document.getElementById("works").className = 'text-secondary line-through'
        window.scrollTo(0, 0);
    }, []);

    const [bg, setBg] = useState('#D2AA5C');
    const [accent, setAccent] = useState('#D2AA5C');
    const [main, setMain] = useState('#D6A158');


    return (
        <div className='bg-primary pb-20'>
            <div className='flex flex-col w-full overflow-x-hidden items-center'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  Freelance</title>
                </Helmet>
                <WorkHeader
                    title='Freelance'
                    bg={bg}
                    accent={accent}
                    timeline='2020 - 2023'
                    role='Designer & Developer'
                    skills='Figma, Adobe, HTML/CSS/Tailwind, Javascript, React'
                    text='#fff'
                />
                <div className='flex flex-col gap-12 w-full items-center my-12'>
                    <Warning title='This is under construction!' 
                    desc='I would love to share the work I have done freelancing, but this page is still under construction. If you would like to hear more, contact me!' 
                    link='mailto:stephaniey.zhang@mail.utoronto.ca' 
                    button='email me :-)' 
                    bg={bg}
                    buttoncolour='#fff'/>
                    <PageFlip next='ICUBE UTM' nextLink='/icube' />
                </div>
            </div>
        </div>
    );
}

export default Freelance;