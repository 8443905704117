import React, { useState } from 'react'
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import dash from '../../assets/images/inform/Dashboard.png';
import landing from '../../assets/images/inform/Landing.png';
import AIsettings from '../../assets/images/inform/AI settings.png';
import UI from '../../assets/images/inform/UI.svg';
import Caption from '../../components/Caption';
import PageFlip from '../../components/PageFlip';
import WorkHeader from '../../components/WorkHeader';
import Paragraph from '../../components/Paragraph';
import ProjectImage from '../../components/ProjectImage';
import Warning from '../../components/Warning';

function Inform() {

    useEffect(() => {
        document.getElementById("works").className = 'transition duration-300 flex flex-col items-center text-lightgrey navbar-links-active'
        window.scrollTo(0, 0);
    }, []);

    const [bg, setBg] = useState('#0D54BE');
    const [accent, setAccent] = useState('#4E8EED');
    const [main, setMain] = useState('#D6A158');

    return (
        <div className='bg-[#fff] pb-20'>
            <div className='flex flex-col w-full overflow-x-hidden items-center bg-[#fff] gap-10 md:gap-20'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  Inform Reviews</title>
                </Helmet>
                <WorkHeader
                    title='Inform Reviews'
                    bg={bg}
                    accent={accent}
                    timeline='2023 - Present'
                    role='VP of Design'
                    skills='Figma, Adobe Illustrator & Adobe After Effects'
                />
                <Paragraph
                    colour={accent}
                    title="What is Inform?"
                    paragraph='Inform is a hands-free review management tool that replies to weekly reviews using our automated assistant. 

                    Inform also provides weekly insights on store performance & reception!
                    Welcome to the future of brand management! 😎' />
                <ProjectImage src={UI} alt='Inform Dashboard' style='w-11/12 md:w-3/4 xl:w-1/2' />
                <Caption text='Inform web pages' bg={bg}/>
                <p className='text-[#4E8EED] text-lg'>Introducing Inform Reviews</p>
                <div className='flex flex-col md:flex-row items-center gap-12 w-11/12 md:w-3/4 xl:w-1/2'>
                    <img src={dash} alt="Inform Dashboard" className='rounded-md shadow-2xl w-full md:w-3/4 xl:w-2/3'></img>
                    <div className='flex flex-col gap-8 self-center'>
                        <div className='flex flex-col gap-4'>
                            <p className='text-[#4E8EED] text-md self-start'>Inform does it all!</p>
                            <p>Access your reviews and reply to them directly from the Inform dashboard</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-12 w-11/12 md:w-3/4 mt-12 xl:w-1/2 items-center'>
                    <div className='flex flex-col gap-8 self-center'>
                        <div className='flex flex-col gap-4'>
                            <p className='text-[#4E8EED] text-md self-start'>Fully customizable</p>
                            <p>Easily customize your AI replies, down to which words to include or avoid and the tone of voice used!</p>
                        </div>
                    </div>
                    <img src={AIsettings} alt="Inform AI settings" className='rounded-md shadow-2xl w-full md:w-3/4 xl:w-2/3'></img>
                </div>
                <ProjectImage src={landing} alt='Inform Landing' style='w-11/12 md:w-3/4 xl:w-1/2' />
                <Caption text='Inform Website' bg={bg}/>
                <Warning
                title='This page is still in progress!'
                desc='I would love to share the work I have done for Inform, but this page is still under construction. If you would like to see the designs up close, contact me!'
                button='email me :-)'
                link='mailto:stephaniey.zhang@mail.utoronto.ca'
                bg={bg}
                buttoncolour='#fff'/>
                <PageFlip next='Hack the Valley' nextLink='/htv' />
            </div>
        </div>
    );
}

export default Inform;